import React from 'react'
import styled from 'styled-components'

export const Text = ({ text, dark, className }) => (
  <StyledText
    className={className ? `text ${className}` : 'text'}
    dark={dark}
    dangerouslySetInnerHTML={{ __html: text }}
  />
)

const StyledText = styled.p`
  font-size: 2rem;
  line-height: 3.5rem;
  font-weight: ${({ theme }) => theme.fontRegular};
  color: ${(props) => (props.dark ? props.theme.black : props.theme.white)};
`
