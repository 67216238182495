import React, { useState, useEffect } from 'react'
import { Text } from './Text'
import { LoadingSpinner } from './LoadingSpinner'
import DatePicker, { registerLocale } from 'react-datepicker'
import styled from 'styled-components'
import axios from 'axios'
import pt from 'date-fns/locale/pt' // the locale you want
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { StaticQuery, graphql } from 'gatsby'
import 'react-datepicker/dist/react-datepicker.css'

import { ConsentGtag } from '../utils/consentGtag'

registerLocale('pt', pt)

export const Form = ({ speciality, white }) => {
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [number, setNumber] = useState('')
  const [email, setEmail] = useState('')
  const [service, setService] = useState('')
  const [date, setDate] = useState(null)
  const [hour, setHour] = useState('')
  const [joinNewsletter, setJoinNewsletter] = useState(false)

  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    ConsentGtag()
    const formData = new FormData()
    formData.append('first_name', name)
    formData.append('last_name', lastName)
    formData.append('number', number)
    formData.append('email', email)
    formData.append('service', service)
    formData.append('date', new Date(date).toLocaleDateString('pt-PT'))
    formData.append('hour', hour)

    axios
      .post('https://ruivilela.pt/form.php', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data; charset=UTF-8',
        },
      })
      .then((res) => {
        var aux = res.data.split("<br>")
        var len = aux.length
        if (aux[len - 1].includes("success")) {
          if (typeof window !== "undefined")
            window.gtag('event', 'form_consulta', {
              form: 'Formulário Consulta',
              service: 'Serviço: ' + service
            })
        } else {
          setHasError(true)
        }
      })
      .catch((err) => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
        setIsSent(true)
      })

    if (joinNewsletter) {
      addToMailchimp(email, { FNAME: name, LNAME: lastName })
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          miscJson {
            form {
              titleSpeciality
              namePlaceholder
              lastNamePlaceholder
              numberPlaceholder
              emailPlaceholder
              servicesPlaceholder
              datePlaceholder
              hourPlaceholder
              servicesOptions
              hoursOptions
              privacyLabel
              newsletterLabel
              btn
              errorMessage
              successMessage
            }
          }
        }
      `}
      render={({ miscJson: content }) => (
        <StyledFormContainer white={white}>
          {isLoading ? (
            <LoadingSpinner light />
          ) : hasError ? (
            <Text className="feedback error" text={content.form.errorMessage} />
          ) : isSent ? (
            <Text
              className="feedback success"
              text={content.form.successMessage}
            />
          ) : (
            <form onSubmit={handleSubmit} id='marcacao'>
              <input
                type="text"
                placeholder={content.form.namePlaceholder}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder={content.form.lastNamePlaceholder}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder={content.form.numberPlaceholder}
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
              <input
                type="email"
                placeholder={content.form.emailPlaceholder}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <select
                defaultValue={speciality ? content.form.servicesOptions.filter(word => word.match(speciality)) : ''}
                onChange={(e) => setService(e.target.value)}
                required
                className={white ? '' : 'white'}
              >
                <option value="" disabled>
                  {content.form.servicesPlaceholder}
                </option>
                {content.form.servicesOptions.map((service) => (
                  <option key={service} value={service}>
                    {service}
                  </option>
                ))}
              </select>

              <div className="inputs-flex">
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  placeholderText={content.form.datePlaceholder}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  locale="pt"
                  required
                />

                <select
                  defaultValue=""
                  onChange={(e) => setHour(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    {content.form.hourPlaceholder}
                  </option>
                  {content.form.hoursOptions.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>
              </div>

              <div className="checkboxes">
                <div className="checkbox">
                  <label>
                    <input type="checkbox" required />
                    <span></span>
                    <p className="white">{content.form.privacyLabel}</p>
                  </label>
                </div>

                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      defaultChecked={joinNewsletter}
                      onChange={(e) => setJoinNewsletter(e.target.checked)}
                    />
                    <span></span>
                    <p className="white">{content.form.newsletterLabel}</p>
                  </label>
                </div>
              </div>

              <button className="white">{content.form.btn}</button>
            </form>
          )}
        </StyledFormContainer>
      )}
    />
  )
}

export const StyledFormContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 28rem;
  margin-top: ${({ theme }) => theme.spacingS};


  .inputs-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacingXS};

    .react-datepicker-wrapper,
    select  {
      margin-top: 0;
      width: 46%;
    }
  }

  input,
  select {
    width: 100%;
    font-size: 1.5rem;
    font-weight: ${({ theme }) => theme.fontSemiBold};
    color: ${({ theme }) => theme.white};
    letter-spacing: 0.03rem;
    padding: ${({ theme }) => `${theme.spacingXXS} ${theme.spacingXS}`};
    background: transparent;
    border: 1px solid ${({ theme }) => theme.white};
    text-transform: uppercase;
    border-radius: 25rem;
    margin-top: ${({ theme }) => theme.spacingXS};

    

    &:first-child {
      margin-top: 0;
    }

    ::placeholder, option{
      font-size: 1.5rem;
      font-weight: ${({ theme }) => theme.fontSemiBold};
      color: ${({ theme }) => theme.white};
    }

    option{
      color: #CEB888;
      background-color: #fff;
    }
  }

  select{

  /* Style */
  width: 100%;
  padding: 0.75rem;

  /* Arrow */
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23${(props) =>
    props.white ? '053d7f' : 'ffffff'}%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E') !important;
  background-repeat: no-repeat;
  background-position: right 2vh top 50%;
  background-size: 0.65rem auto;
  }
  
  .checkboxes {
    margin-top: ${({ theme }) => theme.spacingS};
  }

  .checkbox {
    margin-top: ${({ theme }) => theme.spacingXXS};

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    input {
      opacity: 0;
      width: 1px;
    }

    span {
      display: block;
      width: 1.3rem;
      height: 1.3rem;
      border: 1px solid ${({ theme }) => theme.white};
      border-radius: 50%;
    }

    input:checked ~ span {
      background:${(props) =>
    props.white ? '#053d7f' : '#ffffff'} ;
    }

    p {
      font-size: 1.2rem;
      margin-left: ${({ theme }) => theme.spacingXS};
    }
  }

  button {
    font-size: 2rem;
    font-weight: ${({ theme }) => theme.fontBold};
    letter-spacing: 0.03rem;
    background: transparent;
    text-transform: uppercase;
    text-decoration: underline;
    padding: 0 ${({ theme }) => theme.spacingXS} 0 0;
    margin-top: ${({ theme }) => theme.spacingS};
    cursor: pointer;
  }

  .feedback {
    max-width: 85%;
    margin: 0 auto;
    font-size: 2rem;
    line-height: 2.7rem;
    text-align: center;
    transform: translateY(10rem);
  }

  // Datepicker styles
  .react-datepicker {
    font-size: 1.3rem;
    font-family: ${({ theme }) => theme.fontFamily};
    border: none;
    border-radius: 20px;
    padding: ${({ theme }) => theme.spacingXXS};
  }

  .react-datepicker__current-month {
    font-size: 1.5rem !important;
  }

  .react-datepicker__header {
    padding-top: 6px !important;
  }

  .react-datepicker__day-names {
    margin-top: 6px;
  }

  .react-datepicker__navigation {
    top: -9px !important;
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: ${({ theme }) => theme.blue};
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
  }

  .react-datepicker__navigation-icon--previous::before,
  .react-datepicker__navigation-icon--next::before {
    width: 5px;
    height: 5px;
    border-width: 2px 2px 0 0;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    margin: 0.5rem !important;
  }

  .react-datepicker__header {
    background: ${({ theme }) => theme.white};
    border-bottom: none;
    border-radius: 20px !important;
  }

  .react-datepicker__triangle {
    display: none;
  }
`
